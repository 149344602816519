/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { SET_DEPLOYMENT_KEY } from '../types';

export const setDeploymentKey =
	(deploymentKey: string) => async (dispatch: any) => {
		return dispatch({
			type: SET_DEPLOYMENT_KEY,
			payload: deploymentKey,
		});
	};
