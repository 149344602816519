/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { connect } from 'react-redux';
import { DEPLOYMENT_KEY } from 'config';
import { logOut } from 'store/actions/authActions';
import { clearDashboard } from 'store/actions/dashboardActions';
import { setDeploymentKey } from 'store/actions/settingsActions';

interface MainPageProps {
	isLoggedIn: boolean;
	deploymentKey: string | null;
	logOut: () => void;
	clearDashboard: () => void;
	setDeploymentKey: (deploymentKey: string) => void;
}

const MainPage: React.FC<MainPageProps> = ({
	isLoggedIn,
	deploymentKey,
	logOut,
	clearDashboard,
	setDeploymentKey,
}) => {
	const router = useRouter();

	const checkDeployment = () => {
		if (DEPLOYMENT_KEY !== deploymentKey) {
			clearDashboard();
			logOut();
			setDeploymentKey(DEPLOYMENT_KEY);
		}
	};

	useEffect(() => {
		checkDeployment();

		if (isLoggedIn) router.push('/dashboard/clients');
		else router.push('/auth/login');
	}, [isLoggedIn]);

	return <React.Fragment></React.Fragment>;
};

const mapStateToProps = (state: any) => ({
	isLoggedIn: state.auth.isLoggedIn,
	deploymentKey: state.settings.deploymentKey,
});

const mapDispatchToProps = (dispatch: any) => ({
	setDeploymentKey: (deploymentKey: string) => {
		dispatch(setDeploymentKey(deploymentKey));
	},
	logOut: () => dispatch(logOut()),
	clearDashboard: () => dispatch(clearDashboard()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
